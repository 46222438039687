import { default as Moment, default as moment } from "moment";
import { TNCB } from "../Type/Common/TNCB";
import { TCashlessGarage } from "../Type/Common/TCashlessGarage";
import { TDropdown } from "../Type/Common/TDropdown";
import { differenceInDays, differenceInYears, parse, subDays } from "date-fns";
import { TTWPremiumDetails } from "../Type/TwoWheeler/TTWSingleQuoteDetail";
// import { useAppSelector } from "../Store/hooks";
// const { DROPDOWN_DATA } = useAppSelector((state) => state.Health);
const formats = [
  "YYYY-MM-DD",
  "MM-DD-YYYY",
  "DD-MM-YYYY",
  "YYYY/MM/DD",
  "MM/DD/YYYY",
  "DD/MM/YYYY",
  "MMM DD, YYYY",
  "MMMM DD, YYYY",
  "MMM DD YYYY",
  "MMMM DD YYYY",
  "DD-M-YYYY",
  "YYYY-MM-DD HH:MM:SS",
  "ddd MMM DD YYYY HH:mm:ss GMTZZZZ (Z)",
];

export const checkIfExists = (value: string, array: TDropdown[]): boolean => {
  return array.some((item) => item.label === value);
};

export const isDateInRange = (startDate: Date, endDate: Date): boolean => {
  const currentDate = new Date();
  return currentDate >= startDate && currentDate <= endDate;
};

export const validateChassisNo = (value: string) => {
  const length = `${value}`.length;

  if (length < 6) {
    return true;
  } else {
    return false;
  }
};
export const validateEngineNo = (value: string) => {
  const length = `${value}`.length;

  if (length < 6) {
    return true;
  } else {
    return false;
  }
};
export const validatePrevPolicyNo = (value: string) => {
  const length = `${value}`.length;

  if (length < 5) {
    return true;
  } else {
    return false;
  }
};

export function formatLicensePlate(licensePlate: string) {
  const patterns = [
    /^[A-Z]{2}[0-9]{2}[A-Z]{0,3}[0-9]{4}$/,
    /^[A-Z]{2}[0-9]{1,2}[A-Z]{0,3}[0-9]{4}$/,
    /^[0-9]{2}BH[0-9]{4}[A-Z]{2}$/,
  ];
  for (const pattern of patterns) {
    if (pattern.test(licensePlate)) {
      return licensePlate.replace(
        /(\w{2})(\d{1,2})(\w{0,3})(\d{4})/,
        "$1 $2 $3 $4"
      );
    }
  }
  return null; // Return null if the input doesn't match any pattern
}

export function validateRegno(reg_no: string): boolean {
  const re = /^[A-Z]{2}[0-9]{2}[A-Z]{0,3}[0-9]{4}$/;
  const re_dl = /^[A-Z]{2}[0-9]{1,2}[A-Z]{0,3}[0-9]{4}$/;
  const re_bharat = /^[0-9]{2}BH[0-9]{4}[A-Z]{2}$/;

  if (`${reg_no}`.startsWith("DL")) {
    return re_dl.test(reg_no);
  }

  return re.test(reg_no) || re_bharat.test(reg_no);
}

export function validateRegnoNewMotor(reg_no: string): boolean {
  const regex = /^[A-Za-z]{2}\d{2}(?:[A-Za-z]{2}\d{4})?$/;
  return regex.test(reg_no);
}

export function validateRegNoBharat(pattern: string): boolean {
  const re_bharat = /^[0-9]{2}BH[0-9]{4}[A-Z]{2}$/;
  return re_bharat.test(pattern);
}

/**
 * Validates an email address.
 * @param email - The email address to validate.
 * @returns Returns true if the email is valid, false otherwise.
 */
export function validateEmail(email: string): boolean {
  const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return emailRegex.test(email);
}

/**
 * Validates an Aadhar number.
 * @param aadharNumber - The Aadhar number to validate.
 * @returns Returns true if the Aadhar number is valid, false otherwise.
 */
export function validateAadharNumber(aadharNumber: string): boolean {
  if (aadharNumber !== undefined) {
    const aadhar = aadharNumber.trim();
    if (aadhar.toString().length === 12) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function extractNumbersFromString(inputString: string) {
  // Use a regular expression to match and extract numbers from the input string
  const numbersArray = inputString.match(/\d+/g);

  if (numbersArray) {
    // Join the matched numbers together to form a single string or convert to numbers as needed
    const extractedNumbers = numbersArray.join(""); // This will give you a string of numbers
    // If you want a number, you can convert it like this:
    // const extractedNumbersAsNumber = parseInt(extractedNumbers, 10);
    return extractedNumbers;
  }

  return null; // Return null if no numbers are found in the input string
}

/**
 * Validates a PAN (Permanent Account Number).
 * @param panNumber - The PAN number to validate.
 * @returns Returns true if the PAN number is valid, false otherwise.
 */
export function validatePAN(panNumber: string): boolean {
  const panRegex = /^[A-Z]{5,5}[0-9]{4,4}[A-Z]{1,1}$/;
  return panRegex.test(panNumber);
}

/**
 * Validates a pincode.
 * @param pincode - The pincode to validate.
 * @returns Returns true if the pincode is valid, false otherwise.
 */
export function validatePincode(pincode: string | Date | null): boolean {
  if (typeof pincode === "string") {
    if (pincode === "111111" || pincode === "000000") {
      return false;
    } else if (pincode.charAt(0) === "0") {
      return false;
    } else if (pincode !== undefined) {
      if (pincode.toString().length === 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function validateInput(inputValue: any) {
  if (inputValue.charAt(0) == "0") {
    return;
  } else {
    return inputValue;
  }
}
export function formatDate(date: Date) {
  try {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } catch (error) {
    return "Invalid date format";
  }
}

/**
 * Validates a mobile number.
 * @param mobileNumber - The mobile number to validate.
 * @returns Returns true if the mobile number is valid, false otherwise.
 */
export function validateMobileNumber(
  mobileNumber: string | Date | null
): boolean {
  if (typeof mobileNumber === "string") {
    if (mobileNumber !== undefined && mobileNumber !== null) {
      const mobile = mobileNumber.toString().trim();
      if (
        mobile.toString().length === 10 &&
        parseInt(mobile.substring(0, 1)) >= 6
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function validateMobileOtp(otp: string | null): boolean {
  if (typeof otp === "string") {
    if (otp !== undefined && otp !== null) {
      const receivedOtp = otp.toString().trim();
      if (receivedOtp.toString().length === 4) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export const CHECK_IS_POLICY_ACTIVE = (date: string) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  if (inputDate < currentDate) {
    return false;
  } else if (inputDate > currentDate) {
    return true;
  } else {
    return true;
  }
};

/**
 * Validates a full name.
 * @param fullName - The full name to validate.
 * @returns Returns true if the full name is valid, false otherwise.
 */
export function validateFullName(fullName: string | undefined | null): boolean {
  if (fullName !== undefined && fullName !== null) {
    const name = fullName.trim();
    if (name.includes(" ")) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function calculate_gst(amount: number): number {
  const gst_amount = (amount / 100) * 18;

  return gst_amount;
}

// validate GST
export function validateGST(gstNumber: string): boolean {
  // Updated regular expression to include both uppercase letters and numbers
  const gstRegex =
    /^[0-9]{2}[A-Z0-9]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;

  // Convert gstNumber to uppercase
  const gstString = gstNumber.toUpperCase();

  return gstRegex.test(gstString);
}

/**
 * Formats a number to currency format with Indian Rupee symbol and appropriate separators.
 * @param num - The number to format.
 * @returns Returns the formatted number as a currency string.
 */
export function formatToCurrency(num: number): string {
  if (num === undefined || num === null) {
    return "";
  }
  if (num >= 10000000) {
    return (
      (num / 10000000)
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + " Crore"
    );
  } else if (num >= 100000) {
    return (
      (num / 100000)
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + " Lakh"
    );
  } else {
    return num
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
      .replace("₹", "₹ ");
  }
}
export function formatToCurrencyPir(num: number): string {
  if (num === undefined || num === null) {
    return "";
  }

  let formattedString = "";
  console.log("foooo", num);

  if (num >= 10000000) {
    console.log("num", num);
    const crores = num / 10000000;
    formattedString =
      crores
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + (crores > 1 ? " Crores" : " Crore");
    console.log("bisma", formattedString);
  } else if (num >= 100000) {
    const lakhs = num / 100000;
    formattedString =
      lakhs
        .toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
        .replace("₹", "₹ ") + (lakhs > 1 ? " Lakhs" : " Lakh");
  } else {
    formattedString = num
      .toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      })
      .replace("₹", "₹ ");
  }
  console.log("ggggssss", formattedString);
  return formattedString;
}
export const formatCurrencyAccToUser = (value: number) => {
  // Set a default language tag if countryCode is empty or undefined

  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  });

  // Format the amount using the formatter
  const amount = formatter.format(value);

  // Extract the currency symbol
  const currencySymbol = formatter.formatToParts()[0].value;

  // Manually add a space between the currency symbol and the value
  const formattedAmount = amount.replace(currencySymbol, currencySymbol + " ");

  return formattedAmount;
};

/**
 * Uploads an image file and calls the provided callback function with base64 image data.
 * @param file - The image file to upload.
 * @param callback - The callback function to be called with the base64 image data.
 */

export function uploadImage(
  file: File,
  callback: (base64Image: string, base64String: string) => void
): void {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    const base64String = reader.result as string;
    const base64Image = base64String.replace(
      /^data:(image\/[a-z]+|application\/pdf);base64,/,
      ""
    );
    callback(base64Image, base64String);
  };
}

/**
 * Adds a specified number of years to a given date.
 * @param date - The date to add years to.
 * @param years - The number of years to add.
 * @returns Returns the new date after adding the years in the format "DD-MM-YYYY".
 */
export function addYearsToDate(date: string, years: number): string {
  const newDate = moment(date, ["DD-MM-YYYY", "YYYY-MM-DD"])
    .add(years, "year")
    .format("DD-MM-YYYY");
  return newDate;
}

export function addYearsToDateForonedaysubtract(
  date: Date,
  years: number
): string {
  const newDate = moment(date).add(years, "years");

  // Check for leap year and adjust the date
  if (
    moment(date).isLeapYear() &&
    moment(date).month() === 1 &&
    moment(date).date() === 29 &&
    !newDate.isLeapYear()
  ) {
    newDate.date(28); // Adjust to February 28th if it's a leap day
  }

  const finalDate = newDate.subtract(1, "day").format("DD-MM-YYYY");
  return finalDate;
}

export function FORMAT_YYYY_MM_DD(value: string) {
  let formattedDate = "";

  for (const format of formats) {
    const date = moment(value, format, true);
    if (date.isValid()) {
      formattedDate = date.format("YYYY-MM-DD");
      break;
    }
  }

  if (formattedDate === "") {
    return value; // Return original value if no valid format is found
  }

  return formattedDate;
}

export function FORMAT_DD_MM_YYYY(value: string) {
  const outputFormat = "DD-MM-YYYY";

  if (moment(value, outputFormat, true).isValid()) {
    return value;
  }
  let formattedDate = moment(value).format(outputFormat);

  if (moment(formattedDate, outputFormat, true).isValid()) {
    return formattedDate;
  } else {
    return "";
  }
}

export function GET_CURRENT_DATE() {
  const currentDate = new Date();

  const day = String(currentDate.getDate()).padStart(2, "0");
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const year = currentDate.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

export function FORMAT_DATE_DD_MM_YYYY(value: any): string {
  const formats = [
    "YYYY-MM-DD",
    "MM-DD-YYYY",
    "DD-MM-YYYY",
    "YYYY/MM/DD",
    "MM/DD/YYYY",
    "DD/MM/YYYY",
    "MMM DD, YYYY",
    "MMMM DD, YYYY",
    "MMM DD YYYY",
    "MMMM DD YYYY",
  ];

  let formattedDate = "";

  for (const format of formats) {
    const date = new Date(value);
    const parsedDate = date.toString();
    if (parsedDate !== "Invalid Date") {
      // Pad day and month with leading zero if necessary
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");

      formattedDate = `${day}-${month}-${date.getFullYear()}`;
      break;
    }
  }

  if (formattedDate === "") {
    return value.toString(); // Return original value if no valid format is found
  }

  return formattedDate;
}

/**
 * Formats a number to currency format with Indian Rupee symbol and appropriate separators.
 * @param num - The number to format.
 * @returns Returns the formatted number as a currency string.
 */
export function formatAmountToCurrency(num: number): string {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .format(num)
    .replace("₹", "₹ ");
}

/**
 * Calculates the difference in days between two dates.
 * @param date1 - The first date.
 * @param date2 - The second date.
 * @returns Returns the difference in days between the two dates.
 */
export function calculateDateDifferenceInDays(date2: string): number {
  const date1 = new Date().toLocaleDateString("en-GB");
  const formattedDate1 = Moment(date1, "DD-MM-YYYY").format("YYYY-MM-DD");
  const formattedDate2 = Moment(date2, "DD-MM-YYYY").format("YYYY-MM-DD");
  const msDiff =
    new Date(formattedDate1).getTime() - new Date(formattedDate2).getTime();
  const diff = Math.floor(msDiff / (1000 * 60 * 60 * 24));
  return diff;
}

/**
 * Formats a number with a currency symbol
 *
 * @param value - The number to format.
 * @returns The formatted string with the currency symbol and a space added after it.
 */
export const formatNumberWithCurrencySymbol = (
  value: number | string
): string => {
  // Convert the input to a number if it's a string
  let numericValue: number;

  if (typeof value === "string") {
    // Remove leading and trailing whitespace
    const trimmedValue = value.trim();

    // Check if the trimmed value is empty
    if (trimmedValue === "") {
      return "Empty Value";
    }

    numericValue = parseFloat(trimmedValue);

    // Check if parsing failed
    if (isNaN(numericValue)) {
      return "Invalid Number";
    }
  } else if (typeof value === "number") {
    numericValue = value;
  } else {
    return "Invalid Type"; // Handle other data types
  }

  const nf = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const formattedValue = nf.format(numericValue);

  // Add a space after the currency symbol
  return formattedValue.replace("₹", "₹ ");
};

/**
Calculates the new NCB (No Claim Bonus) based on the provided value.
@param value - The current NCB value.
@returns Returns the new NCB value.
*/
export const CAL_NEW_NCB = (
  value: TNCB,
  claim_made: "Yes" | "No",
  prev_ins?: string,
  policy_expiry_date?: string
): TNCB => {
  if (calculateAgeInDays(`${policy_expiry_date}`) > 90) {
    return "0";
  }

  if (claim_made === "Yes" || prev_ins === "Do not remember") {
    return "0";
  }

  switch (value) {
    case "0":
      return "20";
    case "20":
      return "25";
    case "25":
      return "35";
    case "35":
      return "45";
    case "45":
      return "50";
    case "50":
      return "50";
    default:
      return value;
  }
};

/**
 * Adds the specified number of days to the given date.
 * @param date - The date string in "DD-MM-YYYY" or "YYYY-MM-DD" format.
 * @param days - The number of days to add to the date.
 * @returns The resulting date string after adding the specified number of days.
 */
export function addDaysToDate(date: string, days: number): string {
  const newDate = moment(date, ["DD-MM-YYYY", "YYYY-MM-DD"])
    .add(days, "days")
    .format("DD-MM-YYYY");
  return newDate;
}

export function generateRandomNumberString(length: number): string {
  let result = "";
  const characters = "0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function calculate_age(dateString: any) {
  var birthDate = moment(dateString, "DD-MM-YYYY");
  var today = moment();

  if (!birthDate.isValid()) {
    return NaN; // Return NaN if the date is invalid
  }

  let age = today.diff(birthDate, "years");
  return age;
}
export const isEmpty = (value: string | Date | null | undefined | number) => {
  return (
    value === null ||
    value === undefined ||
    `${value}`.trim().length === 0 ||
    value === "" ||
    value === 0
  );
};

export const LeadingSpaceRemover = (value: any) => {
  const newValue = value.replace(/^\s+/, ""); // Remove leading spaces
  return newValue;
};

export function formatNumberToLakhOrCrores(number: number) {
  if (typeof number !== "number") {
    return "Invalid Input";
  }

  if (number >= 10000000) {
    const formatted = (number / 10000000).toFixed(2);
    return formatted.endsWith(".00")
      ? "₹ " + formatted.slice(0, -3) + " Crore"
      : "₹ " + formatted + " Crore";
  } else if (number >= 100000) {
    const formatted = (number / 100000).toFixed(2);
    return formatted.endsWith(".00")
      ? "₹ " + formatted.slice(0, -3) + " Lakh"
      : "₹ " + formatted + " Lakh";
  } else {
    return "₹ " + number.toString();
  }
}

export function removeLast5Zeros(inputString: string) {
  // Use regular expression to remove the last 5 zeros
  const trimmedString = inputString.replace(/0{5}$/, "");
  return trimmedString;
}
export const hasWarning = (details: any): boolean => {
  return Object.values(details).some(
    (property: any) => property?.warning === true
  );
};

export function calculateAge(dateOfBirth: any) {
  try {
    // console.log("ggg", dateOfBirth);
    // Parse the birthdate using date-fns
    const parsedBirthdate = parse(dateOfBirth, "dd-MM-yyyy", new Date());

    // Calculate the difference in days
    const ageInYears = differenceInYears(new Date(), parsedBirthdate);
    if (!isNaN(ageInYears)) {
      return ageInYears;
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
}
export function calculateAgeInDays(birthdate: string): number {
  try {
    // Parse the birthdate using date-fns
    const parsedBirthdate = parse(birthdate, "dd-MM-yyyy", new Date());

    // Calculate the difference in days
    const ageInDays = differenceInDays(new Date(), parsedBirthdate);
    if (!isNaN(ageInDays)) {
      return ageInDays;
    } else {
      return NaN;
    }
  } catch (error) {
    return NaN;
  }
}

export function calculateDaysLeftInPolicyExpire(dateString: string): string {
  try {
    const today = new Date();
    const expireDate = new Date(dateString);
    const timeDiff = expireDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (daysLeft < 0) {
      return "0";
    } else {
      return `${daysLeft}`;
    }
  } catch (error: any) {
    if (error instanceof Error) {
      return `Error: ${error.message}`;
    }
    return `Error: ${String(error)}`;
  }
}

export function getMonthDifference(date2: any) {
  const d1 = new Date();
  const d2 = new Date(date2);

  const monthsDiff =
    (d2.getFullYear() - d1.getFullYear()) * 12 +
    (d2.getMonth() - d1.getMonth());

  return Math.abs(monthsDiff); // Use Math.abs to get the absolute difference
}

export function subtractYears(date: any, years: any) {
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export function COUNT_NUMBER_OF_CASHLESS_GARAGE_IN_AREA(
  garage: TCashlessGarage[] | undefined,
  rto_data: TDropdown[],
  filter_value: string
) {
  let count = 0;

  const selected_rto_data = rto_data.filter((item) => {
    return item.value.includes(filter_value);
  });

  if (selected_rto_data.length > 0 && garage) {
    const rto_name = selected_rto_data[0].label.split("-")[1].trim();

    const filtered_array = garage?.filter((data) => {
      return data.city_name.includes(rto_name);
    });

    count = filtered_array?.length || 0;
  }

  return count;
}

export function CITY_NAME_ON_BASIS_OF_RTO_CODE(
  RTO_DATA: TDropdown[],
  filter_value: string
) {
  if (RTO_DATA) {
    const selected_rto_data = RTO_DATA.filter((item) => {
      return item.value.includes(filter_value);
    });
    const rto_name = selected_rto_data[0].label.split("-")[1].trim();
    return rto_name;
  }

  return "";
}
export const shouldDisplayAddonTW = (
  label: string,
  premiumDetails: TTWPremiumDetails | undefined
) => {
  if (premiumDetails === undefined) return false;
  switch (label) {
    case "PA Owner Cover":
      return premiumDetails?.paOwnerDriver > 0;
    case "Zero Depreciation":
      return premiumDetails?.depreciationCover > 0;
    case "24x7 Roadside Assistance":
      return premiumDetails?.roadSideAssistance > 0;
    case "Driver Cover":
      return premiumDetails?.driverCover > 0;
    default:
      return false;
  }
};
export function calculate_passenger_cover(
  user_value: any,
  seating_capacity: any,
  PLAN_TYPE: string = "Comprehensive"
) {
  let calculatedPassengerCover = 0;
  if (PLAN_TYPE === "Bundle Policy") {
    calculatedPassengerCover = (user_value / 10000) * 5 * seating_capacity * 3;
  } else {
    calculatedPassengerCover = (user_value / 10000) * 5 * seating_capacity;
  }
  return calculatedPassengerCover;
}

//health care date function
export const DateFun = (originalDate: any) => {
  // Check if originalDate is undefined, null, or not a string
  if (originalDate === null) {
    return;
  }
  if (!originalDate || typeof originalDate !== "string") {
    return "Invalid Date";
  }

  // Split the date string into day, month, and year components
  const [day, month, year] = originalDate?.split("-");

  // Check if day, month, or year is not a valid number
  if (isNaN(parseInt(day)) || isNaN(parseInt(month)) || isNaN(parseInt(year))) {
    return "Invalid Date";
  }

  // Construct a Date object using the components
  const parsedDate = new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day)
  );

  // Check if parsedDate is a valid Date object
  if (isNaN(parsedDate.getTime())) {
    return "Invalid Date";
  }

  // Get the month and year from the parsed date
  const formattedMonth = parsedDate.toLocaleString("en-US", {
    month: "numeric",
  });
  const formattedYear = parsedDate.getFullYear();

  // Format the expected date
  return `${formattedMonth}-${formattedYear}`;
};

export const calculateRoundOffSumInsured = (
  amountInString: string,
  data: any
) => {
  let amount = +amountInString.split(" ")[1];
  let currancy = amountInString.split(" ")[2];

  let amt = Math.ceil(amount);

  if (currancy == "Lakh") {
    amt = amt * 100000;
  } else {
    amt = amt * 10000000;
  }

  for (const g of data) {
    let value = +g.value;

    if (amt <= value) {
      return value.toString();
    }
  }
  // const g = data.map((d: any) => {
  //   // console.log("bisma adata", d);
  //   let value = +d.value;
  //   console.log("bosmammaa value ===> value", value);
  //   if (amt <= value) {
  //     console.log("valueuueueue====> ", value);
  //     return value;
  //   }
  // });
  // console.log("ggggg", g);
  return null;
};
export const calculateRoundOffSumInsuredTerm = (
  amountInString: string,
  data: any
) => {
  console.log("bimsaaaa===>nnn", amountInString);
  let amount = +amountInString.split(" ")[1];
  let currancy = amountInString.split(" ")[2];

  let amt = Math.ceil(amount);

  if (currancy == "Lakh") {
    amt = amt * 100000;
  } else {
    amt = amt * 10000000;
  }

  for (const [index, g] of data.entries()) {
    // console.log("ggggg", g);
    let value = +g.value;
    // console.log("amtttt", amt);
    // console.log("nnnn", value);
    if (amt <= value) {
      return value.toString();
    } else {
      if (index == data.length - 1) {
        return value.toString();
      }
      // console.log("else value ===>", value);
      // return;
    }
  }
  // const g = data.map((d: any) => {
  //   // console.log("bisma adata", d);
  //   let value = +d.value;
  //   console.log("bosmammaa value ===> value", value);
  //   if (amt <= value) {
  //     console.log("valueuueueue====> ", value);
  //     return value;
  //   }
  // });
  // console.log("ggggg", g);
  return null;
};

export const calculateShortOff = (val1: any, val2: any) => {
  if (val1 && val2) {
    console.log("val1", val1);
    console.log("val2", val2);
    const data1 = val1.split(" ")[1];
    const data2 = val2.split(" ")[1];
    const currancy1 = val1.split(" ")[2];
    const currancy2 = val2.split(" ")[2];
    let value1 = data1;
    let value2 = data2;
    if (currancy1 == "Lakhs" || currancy1 == "Lakh") {
      value1 = +`${data1}00000`;
    } else {
      console.log("dec", data1);
      if (data1.includes(".")) {
        const [integerVal, decimalVal] = data1.split(".");
        if (decimalVal.length > 1) {
          value1 = +`${integerVal}${decimalVal}00000`;
        } else {
          value1 = +`${integerVal}${decimalVal}000000`;
        }
      } else {
        value1 = +`${data1}0000000`;
      }
    }
    if (currancy2 == "Lakhs" || currancy2 == "Lakh") {
      value2 = +`${data2}00000`;
    } else {
      if (data2.includes(".")) {
        const [integerVal, decimalVal] = data2.split(".");
        if (decimalVal.length > 1) {
          value2 = +`${integerVal}${decimalVal}00000`;
        } else {
          value2 = +`${integerVal}${decimalVal}000000`;
        }
      } else {
        value2 = +`${data2}0000000`;
      }
    }
    console.log("total1", value1, data1, currancy1);
    console.log("total2", value2, data2, currancy2);
    const totl = value1 - value2;
    console.log("total", totl);
    const g = formatToCurrencyPir(totl);
    console.log("gggg===>", g);
    // if (currancy1 == "Lakhs" && currancy2 == "Lakhs") {
    //   return `${totl} ${totl === 1 ? "Lakh" : "Lakhs"}`;
    // } else {
    //   return `${totl} ${totl === 1 ? "Crore" : "Crores"} `;
    // }
    return g;
  } else {
    return 0;
  }
};

export const totalsumInsuredData = (val1: any) => {
  console.log("val2", val1);
  // if (val1) {
  //   const data = val1.split(" ")[0];
  //   const currancy = val1.split(" ")[1];
  //   if (currancy == "Lakhs") {
  //     // console.log("datatattata=====>", `${data}00000`);
  //     return `${data}00000`;
  //   } else {
  //     return `${data}0000000`; // 140000000
  //   }
  // } else {
  //   return 0;
  // }
  if (val1) {
    // Remove the currency symbol and extract the amount and currency
    const amountAndCurrency = val1
      .replace(/[^0-9. ]/g, "")
      .trim()
      .split(" ");
    console.log("val222", amountAndCurrency);
    const data = amountAndCurrency[0]; // Remove decimal point
    const currency = val1.split(" ")[2];
    console.log("val22", data, currency);
    if (currency === "Lakhs" || currency == "Lakh") {
      return `${data}00000`;
    } else if (currency === "Crores" || currency == "Crore") {
      if (data.includes(".")) {
        const [integerVal, decimalVal] = data.split(".");
        if (decimalVal.length > 1) {
          return `${integerVal}${decimalVal}00000`;
        } else {
          return `${integerVal}${decimalVal}000000`;
        }
      } else {
        return `${data}0000000`;
      }
    } else {
      return 0; // Invalid currency
    }
  } else {
    return 0;
  }
};

export const GET_QUERY_PARAMETERS = (
  parameter:
    | "user_type"
    | "utm_medium"
    | "utm_source"
    | "type"
    | "companyCode"
    | "product_code"
): string => {
  // Access the query parameters directly from the URL
  const searchParams = new URLSearchParams(window.location.search);
  const parameterFromQuery = searchParams.get(parameter);
  if (typeof parameterFromQuery === "string") {
    return parameterFromQuery;
  } else {
    return "";
  }
};
