import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import "../../../../CommonSCSS/ProductPages.scss";
import {
  default as Buttons,
  default as CustomButton,
} from "../../../../Component/Common/CustomButton/CustomButton";
import CustomCheckbox from "../../../../Component/Common/FieldTypes/CustomCheckbox/CustomCheckbox";
import DatePicker from "../../../../Component/Common/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../Component/Common/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../../Component/Common/FieldTypes/SearchDropdown/SearchDropdown";
import Footer from "../../../../Component/Desktop/Footer/Footer";
import Navbar from "../../../../Component/Desktop/Navbar/Navbar";
import FAQ from "../../../../Component/Desktop/ProductPages/FAQ/FAQ";
import MFooter from "../../../../Component/Mobile/MFooter/MFooter";
import MNavbar from "../../../../Component/Mobile/MNavbar/MNavbar";
import { ADD_TW_DTO } from "../../../../DTO/TWDTO/Form/AddTWDTO";
import { COMMON_ROUTES } from "../../../../Router/Path/CommonRoutes";
import { TW_ADD_FORM_SERVICES } from "../../../../Services/TW/TWAddFormServices";
import { UserProfileSlice } from "../../../../Store/Slice_Reducer/Account/UserProfileSlice";
import { HomeSlice } from "../../../../Store/Slice_Reducer/Home/HomeSlice";
import { TWSlice } from "../../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  FORMAT_YYYY_MM_DD,
  GET_QUERY_PARAMETERS,
  calculateAgeInDays,
  isEmpty,
  validateMobileNumber,
  validateRegNoBharat,
  validateRegno,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TTWForm } from "../../../../Type/TTWSlice";
import { CLIENTS } from "../../../../URLCollection/Clients";
import { FRONTEND_DOMAIN } from "../../../../URLCollection/Domain";
import { toast } from "react-toastify";

function TWInsurance() {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const BIKE_INSURANCE = useAppSelector(
    (state) => state.PRODUCT_PAGES.BIKE_INSURANCE.attributes.data
  );

  const { DROPDOWN_DATA, ADD_FORM } = useAppSelector((state) => state.TW);
  const [formData, setFormData] = useState<TTWForm>(ADD_FORM);
  const isMobile = useIsMobile();
  const [pageStatus, setPageStatus] = useState<number>(1);

  useEffect(() => {
    if (pageStatus === 1) {
      setFormData(TWSlice.getInitialState().ADD_FORM);
    }
  }, [pageStatus]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (pageStatus === 3) {
      // get_make_model_list();
      GET_MAKE_LIST();
      get_previous_insurer_list();
    } else if (pageStatus === 2 || pageStatus === 1) {
      get_rto_list();
    }
  }, [pageStatus]);

  useEffect(() => {
    if (!isEmpty(formData?.make?.value)) {
      GET_MODEL_LIST();
    } else {
      dispatch(
        TWSlice.actions.UPDATE_DROPDOWN_MASTER({
          key: "MODEL_LIST",
          value: [],
        })
      );
    }
  }, [formData?.make?.value]);

  //fuel type api call
  // useEffect(() => {
  //   if (formData.make_model.value !== "") {
  //     get_fuel_list();
  //   }
  // }, [formData.make_model.value]);

  useEffect(() => {
    if (!isEmpty(formData.make.value) && !isEmpty(formData.model.value)) {
      get_fuel_list();
    }
  }, [formData.make.value, formData.model.value]);

  // variant api call
  // useEffect(() => {
  //   if (formData.fuel_type.value !== "") {
  //     get_variant_list();
  //   }
  // }, [formData.fuel_type.value]);

  useEffect(() => {
    if (
      !isEmpty(formData.make.value) &&
      !isEmpty(formData.model.value) &&
      !isEmpty(formData.fuel_type.value)
    ) {
      get_variant_list();
    }
  }, [formData.make.value, formData.model.value, formData.fuel_type.value]);

  const get_rto_list = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        const data: Array<{ label: string; value: string }> = [];

        Object.entries(response).map(([brand, value]) =>
          data.push({ label: `${brand} - ${value}`, value: brand })
        );

        dispatch(
          TWSlice.actions.SET_DROPDOWN_DATA({
            key: "RTO_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_RTO_LIST(onError, onSuccess);
  };

  const get_previous_insurer_list = () => {
    const onSuccess = (res: any) => {
      const main_response = res;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          short_name?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        data.push({ label: "Do not remember", value: "Do not remember" });
        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.short_name === "string") {
            data.push({
              label: `${value.short_name}`,
              value: `${value.short_name}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.SET_DROPDOWN_DATA({
            key: "PREVIOUS_INSURER_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_PREVIOUS_INSURER_LIST(onError, onSuccess);
  };

  const GET_MAKE_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make === "string") {
            data.push({ label: `${value.make}`, value: `${value.make}` });
          }
        });

        dispatch(
          TWSlice.actions.SET_DROPDOWN_DATA({
            key: "MAKE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_MAKE_LIST(onError, onSuccess);
  };

  const GET_MODEL_LIST = () => {
    const onSuccess = (res: any) => {
      const main_response = res?.data;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          make_model?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.make_model === "string") {
            data.push({
              label: `${value.make_model}`,
              value: `${value.make_model}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.SET_DROPDOWN_DATA({
            key: "MODEL_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_MODEL_LIST(
      onError,
      onSuccess,
      `${formData.make.value}`
    );
  };

  const get_fuel_list = () => {
    const onSuccess = (res: any) => {
      const main_response = res;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          fuel_type?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.fuel_type === "string") {
            data.push({
              label: `${value.fuel_type}`,
              value: `${value.fuel_type}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.SET_DROPDOWN_DATA({
            key: "FUEL_TYPE_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error");
    };
    TW_ADD_FORM_SERVICES.GET_FUEL_TYPE_LIST(
      onSuccess,
      onError,
      `${formData.make.value} ${formData.model.value}`
    );
  };

  const get_variant_list = () => {
    const onSuccess = (res: any) => {
      const main_response = res;
      const response = main_response?.response;
      const error = main_response?.error;

      if (error === false) {
        type DataType = {
          variant_cc?: string;
        };

        const datas: Record<string, DataType> = {
          ...response,
        };

        const data: Array<{ label: string; value: string }> = [];

        Object.entries(datas).map(([key, value]) => {
          if (value && typeof value.variant_cc === "string") {
            data.push({
              label: `${value.variant_cc}`,
              value: `${value.variant_cc}`,
            });
          }
        });

        dispatch(
          TWSlice.actions.SET_DROPDOWN_DATA({
            key: "VARIANT_LIST",
            value: data,
          })
        );
      }
    };

    const onError = (res: any) => {
      console.log("Error", res);
    };
    TW_ADD_FORM_SERVICES.GET_VARIANT_LIST(
      onSuccess,
      onError,
      `${formData.make.value} ${formData.model.value}`,
      `${formData.fuel_type.value}`
    );
  };

  // form field update function
  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "make") {
      setFormData((prev) => ({
        ...prev,
        make: { value: value, warning: false },
        fuel_type: { ...prev.fuel_type, value: "" },
        variant_cc: { ...prev.variant_cc, value: "" },
      }));
    } else if (attrName === "model") {
      setFormData((prev) => ({
        ...prev,
        model: { value: value, warning: false },
        fuel_type: { ...prev.fuel_type, value: "" },
        variant_cc: { ...prev.variant_cc, value: "" },
      }));
    } else if (attrName === "fuel_type") {
      setFormData((prev) => ({
        ...prev,
        fuel_type: { value: value, warning: false },
        variant_cc: { ...prev.variant_cc, value: "" },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "reg_no"
              ? !validateRegno(value)
              : attrName === "mobile"
              ? !validateMobileNumber(value)
              : false,
        },
      }));
    }
  };

  // get the vehcile detail as per the registor number
  const get_vehicle_detail = () => {
    const toastLoader = toast.loading("Fetching vehicle info...");
    const onSuccess = (res: any) => {
      const results = res;
      const response = results?.response;
      const error = results?.error;
      if (error) {
        toast.dismiss(toastLoader);
        toast.error("Failed to fetch vehicle info.");
      } else {
        const make = response?.make;
        const model = response?.model;
        const make_model = `${make} ${model}`;
        const variant_cc = `${response?.variant_cc}`;
        const fuel_type = response?.fuel_type;
        const registration_date = response?.registration_date;
        let data: TTWForm = { ...formData };
        if (
          !isEmpty(make) &&
          !isEmpty(make_model) &&
          !isEmpty(variant_cc) &&
          !isEmpty(fuel_type)
        ) {
          data = {
            ...formData,
            make: { value: make, warning: false },
            model: { value: model, warning: false },
            make_model: { value: make_model, warning: false },
            variant_cc: { value: variant_cc, warning: false },
            fuel_type: { value: fuel_type, warning: false },
            reg_date: {
              value: FORMAT_DATE_DD_MM_YYYY(registration_date),
              warning: false,
            },
          };

          setFormData(data);
        }
        toast.dismiss(toastLoader);
        window.location.href = `${FRONTEND_DOMAIN}${
          COMMON_ROUTES.TW_FORM
        }?data=${JSON.stringify(data)}&utm_source=${GET_QUERY_PARAMETERS(
          "utm_source"
        )}&utm_medium=${GET_QUERY_PARAMETERS("utm_medium")}`;
      }
    };
    const onError = (e: any) => {
      console.log(e);
      toast.dismiss(toastLoader);
    };

    TW_ADD_FORM_SERVICES.GET_VEHICLE_INFO(onSuccess, onError, {
      type: "TW",
      rc_no: formData.reg_no.value,
    });
  };
  // form validation
  const validateForm = () => {
    let data = { ...formData };

    data = {
      ...data,
      reg_no: {
        ...data.reg_no,
        warning: !validateRegno(`${data.reg_no.value}`),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(`${data.mobile.value}`),
      },
      business_type: "Rollover",
    };

    if (validateRegNoBharat(data.reg_no.value)) {
      data = {
        ...data,
        rto: {
          ...data.rto,
          warning: isEmpty(`${data.rto.value}`),
        },
      };
    }

    setFormData({ ...data });
    if (!data.reg_no.warning && !data.mobile.warning) {
      get_vehicle_detail();
    }
  };

  // api call and send parameter to the backend
  const add_form = () => {
    const onSuccess = (res: any) => {
      console.log(res);

      const response = res.response;
      dispatch(
        UserProfileSlice.actions.setAccountUserDataMultipleKey([
          { key: "name", value: formData.name.value },
          { key: "mobile", value: formData.mobile.value },
        ])
      );

      window.location.href = `https://${CLIENTS.BIMASTREET_QUOTE}${COMMON_ROUTES.TW_QUOTE}?type=TW&quote_no=${response}`;
    };
    const onError = (err: any) => {};
    let param: ADD_TW_DTO = {
      twBusinessType: formData.rto.value ? "New" : "Rollover",
      twMakeModel: `${formData.make.value} ${formData.model.value}`,
      twFuelType: formData.fuel_type.value,
      twVariantCC: formData.variant_cc.value,
      twRegNo: formData.reg_no.value,
      twClaimMade: formData.claimed,
      twRtoCode: formData.rto.value,
      twPExpiryDate:
        formData.business_type === "Rollover"
          ? FORMAT_YYYY_MM_DD(formData.policy_expiry_date.value)
          : "",
      twRegDate:
        formData.business_type === "Rollover"
          ? FORMAT_YYYY_MM_DD(formData.reg_date.value)
          : "",
      twPreviousNCB: formData.prev_ncb,
      twPreviousInsurer: formData.previous_insurer.value,
      twMobile: formData.mobile.value,
    };
    dispatch(HomeSlice.actions.setPageStatus("TW"));
    TW_ADD_FORM_SERVICES.ADD_FORM_DATA(onSuccess, onError, param);
  };

  const backnavigate = () => {
    let dataBack = pageStatus;
    if (dataBack === 3 && formData.rto.value !== "") {
      setPageStatus(2);
    } else {
      setPageStatus((prev) => (prev - 1 === 2 ? 1 : prev - 1));
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Best Bike Insurance Online | Best 2 Wheeler Insurance & Policies |
          Affordable Bike Insurance Companies
        </title>

        {/* Meta Description */}
        <meta
          name="description"
          content="Explore the best bike insurance online! Secure your ride with our quick and easy policy. Bike Insurance Renewal and purchase made simple. Get a bike insurance quote now!"
        />

        {/* Meta Keywords */}
        <meta
          name="keywords"
          content="Bike Insurance Online, Best Bike Insurance Companies, Bike Insurance Policy Online, 2 Wheeler Insurance, Two Wheeler Insurance, Best Bike Insurance, Best Bike Insurance Companies	"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bimastreet" />
        <meta
          property="og:title"
          content="Best Bike Insurance Online | Best 2 Wheeler Insurance & Policies | Affordable Bike Insurance Companies"
        />
        <meta
          property="og:description"
          content="Explore the best bike insurance online! Secure your ride with our quick and easy policy. Bike Insurance Renewal and purchase made simple. Get a bike insurance quote now!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`${FRONTEND_DOMAIN}${location.pathname}`}
        />
        <meta
          property="og:image"
          content="https://www.bimastreet.com/images/bike_product_icon.svg"
        />
        <link rel="icon" href="%PUBLIC_URL%/favicon.ico" type="image/x-icon" />
        <link rel="canonical" href={`${FRONTEND_DOMAIN}${location.pathname}`} />
      </Helmet>

      {isMobile ? <MNavbar /> : <Navbar />}
      <Box className="productPagesWrapper">
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h1>
              <img src="./images/bike_product_icon.svg" height="67px" />{" "}
              {BIKE_INSURANCE?.title}
            </h1>
            <p style={{ opacity: "0.5" }}>{BIKE_INSURANCE?.desc}</p>
          </Grid>
        </Grid>

        {/* Header / From */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing columnReverse"
        >
          <Grid lg={6} xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: BIKE_INSURANCE?.primary_info?.title,
              }}
            />
            <p style={{ marginBottom: "24px", marginTop: "24px" }}>
              {BIKE_INSURANCE?.primary_info?.desc}
            </p>
            <ul>
              <li>
                <h5 className="tickTitle">Third-Party Bike Insurance</h5>
              </li>
              <li>
                <h5 className="tickTitle">Comprehensive Bike Insurance</h5>
              </li>
              <li>
                <h5 className="tickTitle">Standalone Own Damage</h5>
              </li>
            </ul>
          </Grid>
          <Grid lg={6} xs={12}>
            <Box className="formSection twbg">
              <Grid
                container
                columnSpacing={3}
                rowSpacing={0}
                justifyContent="center"
              >
                <Grid xs={12} textAlign="center" marginBottom="38px">
                  <h1>
                    Find the <span className="greenText">Best</span>{" "}
                    <span>Bike Insurance Plan</span>
                  </h1>
                </Grid>
                <Grid
                  xs={12}
                  sm={6}
                  sx={{ maxWidth: "244px", marginBottom: "24px" }}
                >
                  <RKTextField
                    class_name="inputField vehicleNumberField mb-5"
                    title={""}
                    value={formData.reg_no.value}
                    attrName={"reg_no"}
                    value_update={updateMasterState}
                    warn_status={formData.reg_no.warning}
                    error_message={
                      isEmpty(formData.reg_no.value)
                        ? "Enter vehicle no."
                        : "Enter valid vehicle no."
                    }
                    placeholder={"BIKE NUMBER"}
                    validation_type="ALL_CAPS"
                    max_length={11}
                  />
                  <RKTextField
                    class_name="inputField "
                    validation_type="NUMBER"
                    title={"Mobile"}
                    value={formData.mobile.value}
                    attrName={"mobile"}
                    value_update={updateMasterState}
                    warn_status={formData.mobile.warning}
                    max_length={10}
                    error_message={
                      isEmpty(formData.mobile.value)
                        ? "Enter Mobile Number"
                        : "Enter valid mobile number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid
                container
                columnSpacing={3}
                rowSpacing={3}
                justifyContent="center"
              >
                <Grid xs={12} className="ctaBtn mt-4" textAlign="center">
                  <CustomButton
                    class_name="greenXlBtn"
                    text_name={pageStatus < 4 ? "View Prices" : "View Quotes"}
                    onClickFunction={validateForm}
                  />
                </Grid>
                {pageStatus === 1 ? (
                  <Grid xs={12} textAlign="center">
                    <p className="newAndRenew">
                      Click here for{" "}
                      <Link
                        onClick={() => {
                          window.location.href = `${FRONTEND_DOMAIN}${
                            COMMON_ROUTES.TW_FORM
                          }?data=${JSON.stringify({
                            ...formData,
                            business_type: "New",
                          })}`;
                        }}
                      >
                        BRAND NEW BIKE INSURANCE
                      </Link>
                    </p>
                  </Grid>
                ) : null}
                {pageStatus === 4 ? (
                  <Grid
                    xs={12}
                    className="disabledCheckbox"
                    textAlign={"center"}
                  >
                    <CustomCheckbox
                      label={
                        <>
                          By clicking on view quotes you agree to all{" "}
                          <a>Terms & Conditions</a>.
                        </>
                      }
                      value={true}
                      defaultChecked
                      disabled={true}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px"
            >
              <Grid container columnSpacing={3} rowSpacing={0}>
                <Grid lg={12} md={12}>
                  <h2 style={{ marginBottom: "24px" }}>
                    {BIKE_INSURANCE?.gradient_box_title}
                  </h2>
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} rowSpacing={0}>
                {BIKE_INSURANCE?.gradient_data?.map((data) => (
                  <Grid md={6}>
                    <Box className="boderWhitegradientBox">
                      <h5 className="tickTitle">{data.title}</h5>
                      <p>{data.desc}</p>
                    </Box>
                  </Grid>
                ))}
                <Grid xs={12} marginTop={"12px"}>
                  <Box
                    padding={"12px 30px"}
                    sx={{ background: "#ffffff", borderRadius: "50px" }}
                    textAlign={"center"}
                  >
                    <p>{BIKE_INSURANCE.gradient_note}</p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px"
            >
              <Box className="inner">
                <img src="./images/shield-icon.svg" width="58px" />
                <div style={{ flex: "1 0 0" }}>
                  <h4>{BIKE_INSURANCE.buy_quote?.title}</h4>
                  <p>{BIKE_INSURANCE.buy_quote?.desc}</p>
                </div>
                <div className="ctaBtn">
                  <Buttons
                    onClickFunction={scrollToTop}
                    class_name="greenMdBtn"
                    text_name="Buy Now"
                  />
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12}>
            <h2
              dangerouslySetInnerHTML={{
                __html: BIKE_INSURANCE?.list_cont_heading?.title,
              }}
            />
            <p style={{ marginBottom: "40px", marginTop: "24px" }}>
              {BIKE_INSURANCE?.list_cont_heading?.desc}
            </p>
            <ul className="numberList">
              {BIKE_INSURANCE.list_cont_data.map((data, index) => (
                <li>
                  <span>{index + 1}</span>
                  <div>
                    <h4>{data?.title}</h4>
                    <p>{data?.desc}</p>
                  </div>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="greybg sectionSpacing"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              display="flex"
              flexWrap="wrap"
              padding="20px 30px"
              justifyContent="center"
              alignItems="center"
              gap="24px"
              alignSelf="stretch"
            >
              <img src="./images/policy-ponts.svg" width="141px" />
              <p>{BIKE_INSURANCE.product_info}</p>
            </Box>
          </Grid>
        </Grid>

        {/* FAQ */}
        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h2 style={{ marginBottom: "18px" }}>
              {BIKE_INSURANCE?.faq_heading?.title}
            </h2>
            <p style={{ marginBottom: "18px" }}>
              {BIKE_INSURANCE?.faq_heading?.desc}
            </p>
          </Grid>
          <Grid xs={12}>
            <FAQ data={BIKE_INSURANCE?.faq_data} />
          </Grid>
        </Grid>

        <Grid
          container
          columnSpacing={3}
          rowSpacing={0}
          className="sectionSpacing"
        >
          <Grid xs={12} textAlign="center">
            <h4 style={{ marginBottom: "18px" }}>
              {BIKE_INSURANCE?.disclaimer}
            </h4>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid xs={12}>
            <Box
              className="recommendation"
              maxWidth="1338px"
              marginLeft="auto"
              marginRight="auto"
              borderRadius="12px"
              padding="24px 12px"
            >
              <Box className="inner">
                <img src="./images/idea.svg" width="94px" />
                <div style={{ flex: "1 0 0" }}>
                  <p>{BIKE_INSURANCE.faq_note}</p>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isMobile ? <MFooter /> : <Footer />}
    </>
  );
}

export default TWInsurance;
